import React from "react";
import "../styles/EnquireNowButton.css"; 

const EnquireNowButton = () => {
    const handleScrollToEnquire = () => {
      const enquireSection = document.querySelector("#enquire");
      if (enquireSection) {
        enquireSection.scrollIntoView({
          behavior: "smooth",
          block: "start", 
        });
      }
    };
    return (
      <div className="enquire-now-button" onClick={handleScrollToEnquire}>
        REQUEST A CALLBACK
      </div>
    );
  };
  
export default EnquireNowButton;