import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import "../styles/NakshatraVeda.css";

const ProjectDescription = () => {
  const { projectId } = useParams();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [showMore, setShowMore] = useState(false);

  // Define content for different project IDs
  const projectContent = {
    "nakshatra-veda-i": {
      title: "NAKSHATRA VEDA I & II",
      overview: [
        "This 23-story high-rise building in Vasai represents the everlasting joy and harmony of Nakshatra Veda -I.",
        "With Nakshatra Veda -I, you will experience a state of blissful magnificence. The broad classical staircase and a well-maintained lift are accessible near the lobby. The common room is decorated with twinkling, appealing walls and a welcoming ambience.",
        "There is no compromise when it comes to the safety of you and your loved ones! There is no tolerance for compromises at the Nakshatra Veda -I. For the purpose of preventing untoward incidents from spreading further, we have installed strong firefighting equipment.",
        "There is a well-equipped playground for the children to play and enjoy their day with their friends and other residents to spend some quality time. Additionally, the active landscaping is a real delight for the eyes.",
        "We introduced a novel initiative with innovative solutions with a keen eye on the environment through an optimistic approach. In Nakshatra Veda -I, blissful, eco-friendly life is a dream that many people have, and we are confident that we can make your dream a reality!",
      ],
    },
    "nakshatra-aazstha": {
      title: "NAKSHATRA AAZSTHA",
      overview: [
        "This 33-story high-rise building in Vasai represents the everlasting joy and harmony of Nakshatra Aazstha.",
        "With Nakshatra Aazstha, you will experience a state of blissful magnificence. The broad classical staircase and a well-maintained lift are accessible near the lobby. The common room is decorated with twinkling, appealing walls and a welcoming ambience.",
        "There is no compromise when it comes to the safety of you and your loved ones! There is no tolerance for compromises at the Nakshatra Aazstha. For the purpose of preventing untoward incidents from spreading further, we have installed strong firefighting equipment.",
      ],
    },
    "nakshatra-gokul": {
      title: "NAKSHATRA GOKUL",
      overview: [
        "This 25-story high-rise building in Vasai represents the everlasting joy and harmony of Gokul Nakshatra.",
        "With Gokul Nakshatra, you will experience a state of blissful magnificence. The broad classical staircase and a well-maintained lift are accessible near the lobby. The common room is decorated with twinkling, appealing walls and a welcoming ambience.",
        "There is no compromise when it comes to the safety of you and your loved ones! There is no tolerance for compromises at the Gokul Nakshatra. For the purpose of preventing untoward incidents from spreading further, we have installed strong firefighting equipment.",
        "There is a well-equipped playground for the children to play and enjoy their day with their friends and other residents to spend some quality time. Additionally, the active landscaping is a real delight for the eyes.",
      ],
    },
    "nakshatra-prithvi": {
      title: "NAKSHATRA PRITHVI",
      overview: [
        "This 21-story high-rise building in Vasai represents the everlasting joy and harmony of Nakshatra Prithvi.",
        "With Nakshatra Prithvi, you will experience a state of blissful magnificence. The broad classical staircase and a well-maintained lift are accessible near the lobby. The common room is decorated with twinkling, appealing walls and a welcoming ambience.",
        "There is no compromise when it comes to the safety of you and your loved ones! There is no tolerance for compromises at the Nakshatra Prithvi. For the purpose of preventing untoward incidents from spreading further, we have installed strong firefighting equipment.",
        "There is a well-equipped playground for the children to play and enjoy their day with their friends and other residents to spend some quality time. Additionally, the active landscaping is a real delight for the eyes.",
        "We introduced a novel initiative with innovative solutions with a keen eye on the environment through an optimistic approach. In Nakshatra Prithvi, blissful, eco-friendly life is a dream that many people have, and we are confident that we can make your dream a reality!",
      ],
    },
    "nakshatra-aarambh": {
      title: "NAKSHATRA AARAMBH",
      overview: [
        "This 23-story high-rise building in Naigaon represents the everlasting joy and harmony of Nakshatra Aarambh.",
        "With Nakshatra Aarambh, you will experience a state of blissful magnificence. The broad classical staircase and a well-maintained lift are accessible near the lobby. The common room is decorated with twinkling, appealing walls and a welcoming ambience.",
        "There is no compromise when it comes to the safety of you and your loved ones! There is no tolerance for compromises at the Nakshatra Aarambh. For the purpose of preventing untoward incidents from spreading further, we have installed strong firefighting equipment.",
        "There is a well-equipped playground for the children to play and enjoy their day with their friends and other residents to spend some quality time. Additionally, the active landscaping is a real delight for the eyes.",
        "Stepping into Naigaon means stepping into the zone of easy accessibility. Naigaon and Juchandra railway stations are a key takeaway. The project is strategically located to ensure easy connectivity with the key links, Western Express Highway, Mumbai-Ahmedabad Highway, and the East-West Bridge. In case of an emergency, the area is served by various hospitals like Evershine Hospital, Platinum Hospitals, and Neolife Hospital. Seven Square Academy, DonBosco School, and many more established institutions are close to the site.",
        "We introduced a novel initiative with innovative solutions with a keen eye on the environment through an optimistic approach. In Nakshatra Aarambh, blissful, eco-friendly life is a dream that many people have, and we are confident that we can make your dream a reality!",
      ],
    },
    "shree-ram-nagar": {
      title: "SHREE RAM COMPLEX",
      overview: [
        "Welcome to Shree Ram Complex, a prestigious community in Shree Ram Nagar, Virar West, where sophistication and convenience converge. Our exclusive offerings include:",
        "<strong>Luxurious Villas:</strong> Indulge in unparalleled comfort with our bespoke villas at Shree Ram Square. These exquisite homes feature expansive, beam-free interiors, crafted with modern elegance and equipped with premium utilities. Enjoy serene living amidst thoughtfully curated amenities and effortless access to life’s essentials, all within a distinguished and plush environment.",
        "<strong>Prime Commercial Spaces:</strong> Elevate your business in style with our premium commercial spaces at Shree Ram Square. Our properties include ground-floor retail shops and expansive office spaces, both meticulously designed with wide, unobstructed layouts and upscale finishes. Situated on a prominent corner plot, these spaces offer exceptional visibility and accessibility, ideal for distinguished retail and professional ventures.",
        "<strong>Refined Tower Residences:</strong>Experience elevated living at Shree Ram Heights, where sophistication meets impeccable design. Our upscale apartments boast contemporary interiors with luxurious features and conveniences. Residents enjoy an array of high-end amenities and seamless access to the vibrant surroundings of Shree Ram Nagar, ensuring a lifestyle of ultimate comfort and ease.",
        "At Shree Ram Complex, immerse yourself in a world of refined living and premier commercial opportunities, all set in a thriving, upscale community.",
      ],
    },
    "nakshatra-auris": {
      title: "NAKSHATRA AURIS",
      overview: [
        "Discover The Urban Luxury at Nakshatra Auris, a high-rise tower that redefines modern living. We take pride in creating a space for those who seek to stand apart, a haven designed for individuals who envision the world in their own unique way.",
        "Nestled within lush green surroundings and connected to a smart, well-connected location, Nakshatra Auris offers a blend of serenity and convenience. Enjoy access to top-tier amenities, thoughtfully designed landscapes, and spacious residences that cater to your every need.",
        "With all major brands and services close by, Nakshatra Auris truly is the perfect destination for urban enthusiasts looking for something extraordinary.",
      ],
    },
  };

  const content = projectContent[projectId];

  if (!content) {
    return <div>Project not found.</div>;
  }

  const handleToggle = () => {
    setShowMore(!showMore);
  };
  return (
    <div id="overview" className="overview-section">
      <h1>
        {isSmallScreen ? (
          <>
            {content.title.split(" ").slice(0, 2).join(" ")}
            <br />
            {content.title.split(" ").slice(2).join(" ")}
          </>
        ) : (
          <>
            {content.title.split(" ").slice(0, 2).join(" ")}
            <br />
            {content.title.split(" ").slice(2).join(" ")}
          </>
        )}
      </h1>
      <h2>Overview</h2>
      {content.overview
        .slice(0, showMore ? content.overview.length : 2)
        .map((paragraph, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      {content.overview.length > 2 && (
        <div className="read-more-button-container">
          <button className="read-more-button" onClick={handleToggle}>
            {showMore ? "Read Less" : "Read More"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectDescription;
