import React from "react";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Container } from "@mui/material";

import initialBackground from "../../images/Homepage-01-Nakshatra Veda.webp";
import secondBackground from "../../images/Homepage-02-Nakshatra Aaztha.webp";
import thirdBackground from "../../images/Homepage-03-Nakshatra Prithvi.webp";
import fourthBackground from "../../images/Homepage-04-Nakshatra Gokul.webp";
import FifthBackground from "../../images/Homepage-05-Nakshatra Auris.webp";

import "../../styles/DesktopHomePage.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  { url: initialBackground, text: "Nakshatra Veda I & II, Vasai" },
  { url: secondBackground, text: "Aazstha, Vasai" },
  { url: thirdBackground, text: "Nakshatra Prithvi, Vasai" },
  { url: fourthBackground, text: "Nakshatra Gokul, Virar" },
  { url: FifthBackground, text: "Nakshatra Auris, Nalasopara" },
  { url: initialBackground, text: "Nakshatra Veda I & II, Vasai" },
  { url: secondBackground, text: "Aazstha, Vasai" },
  { url: thirdBackground, text: "Nakshatra Prithvi, Vasai" },
  { url: fourthBackground, text: "Nakshatra Gokul, Virar" },
  { url: FifthBackground, text: "Nakshatra Auris, Nalasopara" },

];

const DesktopHomePage = ({ scrollToSection }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div id="home" className="homepage">
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
        className="carousel"
        style={{ overflow: "hidden" }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="carousel-item"
            style={{
              backgroundImage: `url(${image.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <img
              src={image.url}
              alt={`Slide ${index}`}
              className="carousel-image"
              loading="lazy"
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <Container className="content">
        <div className="whereQualityMeetsContainer">
          <span className="whereQualityMeetsContainer1">
            <p className="whereQuality">
              <span className="where">Where </span>
              <span>quality</span>
            </p>
            <p className="meetsYourDreams">
              <span>meets your </span>
              <span className="dreams">dreams </span>
            </p>
          </span>
        </div>
        {/* <br /> */}
        <br />
        <div className="text-above-button">
          <p>{images[activeStep].text}</p>
        </div>
        <div className="ctaButtonContainer">
          <button className="enquireButton" onClick={() => scrollToSection()}>
            ENQUIRE NOW
          </button>
        </div>
      </Container>
    </div>
  );
};

export default DesktopHomePage;
