import React, { useState } from "react";
import "../styles/DownloadBrochure.css";

const DownloadBrochureForm = ({ brochureUrl }) => {
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, contactNumber, email } = formData;

    if (!name.trim()) newErrors.name = "Name is required.";
    if (!contactNumber.trim())
      newErrors.contactNumber = "Contact number is required.";
    if (!email.trim()) newErrors.email = "Email is required.";

    if (contactNumber && !/^\d{10}$/.test(contactNumber)) {
      newErrors.contactNumber = "Contact number must be exactly 10 digits.";
    }

    if (
      email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
    ) {
      newErrors.email = "Email is invalid.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!validateForm()) return;
  
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/");
    const projectName = pathSegments.includes("projects")
      ? pathSegments[pathSegments.indexOf("projects") + 1]
      : "";
  
    if (!projectName) {
      console.error("Project name could not be determined from URL.");
      alert("Invalid URL: Unable to determine project name.");
      return;
    }
  
    const data = {
      ...formData,
      projectName: projectName.replace(/-/g, " "),
    };
  
    console.log("Payload sent to API:", data);
  
    fetch("https://api.shvx.in/api/v1/lead/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eGzFywzn9i0S", //Live
          // Authorization: "Bearer 0eUjiXPjrSYy", //dummy for testing
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("API Response:", response); 
        if (response.success) {
          console.log("Data submitted successfully:", response);
  
          const link = document.createElement("a");
          link.href = brochureUrl;
          link.target = "_blank";
          link.download = "Brochure.pdf";
          link.click();
        } else {
          console.error("Failed to submit data:", response);
          alert(response.message || "Failed to submit data. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        alert("An error occurred. Please try again.");
      });
  };
  
  return (
    <div className="download-brochure-form">
      <form onSubmit={handleSubmit} className="form-container">
        <input
          type="text"
          name="name"
          placeholder="Full Name *"
          value={formData.name}
          onChange={handleChange}
          className={`form-input ${errors.name ? "input-error" : ""}`}
        />
        {errors.name && <small className="error-text">{errors.name}</small>}

        <input
          type="text"
          name="contactNumber"
          placeholder="Mobile Number *"
          value={formData.contactNumber}
          onChange={handleChange}
          className={`form-input ${errors.contactNumber ? "input-error" : ""}`}
        />
        {errors.contactNumber && (
          <small className="error-text">{errors.contactNumber}</small>
        )}

        <input
          type="email"
          name="email"
          placeholder="Email ID *"
          value={formData.email}
          onChange={handleChange}
          className={`form-input ${errors.email ? "input-error" : ""}`}
        />
        {errors.email && <small className="error-text">{errors.email}</small>}

        <button type="submit" className="download-button">
          Download Brochure
        </button>
      </form>
    </div>
  );
};

export default DownloadBrochureForm;
