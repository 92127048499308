import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../styles/Header.css";
import logo from "../images/JSB_Logo_latest.png";

const HeaderVersion = ({
  homeRef,
  overviewRef,
  amenitiesRef,
  floorPlanRef,
  locationRef,
  galleryRef,
  enquireRef,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get("utm_source");
  const utmMedium = queryParams.get("utm_medium");
  const utmId = queryParams.get("utm_id");
  const gadSource = queryParams.get("gad_source");
  const hasUTMParams = utmSource || utmMedium || utmId || gadSource;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 70; // Offset for sticky header

      const sections = [
        {  ref: homeRef, id: "home"},
        { ref: overviewRef, id: "overview" },
        { ref: amenitiesRef, id: "amenities" },
        { ref: floorPlanRef, id: "floorPlan" },
        { ref: locationRef, id: "location" },
        { ref: galleryRef, id: "gallery" },
        { ref: enquireRef, id: "enquire" },
      ];

      let currentActiveLink = "";

      sections.forEach((section) => {
        if (section.ref.current) {
          const { offsetTop, offsetHeight } = section.ref.current;
          if (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + offsetHeight
          ) {
            currentActiveLink = section.id;
          }
        }
      });

      setActiveLink(currentActiveLink);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger once on mount

    return () => window.removeEventListener("scroll", handleScroll);
  }, [
    homeRef,
    overviewRef,
    amenitiesRef,
    floorPlanRef,
    locationRef,
    galleryRef,
    enquireRef,
  ]);

  const handleScrollToSection = (sectionRef, linkName) => {
    setActiveLink(linkName);
    const SCROLL_OFFSET = 70; // Adjust this value based on your header height
    if (sectionRef?.current) {
      // Scroll using ref
      window.scrollTo({
        top: sectionRef.current.offsetTop - SCROLL_OFFSET,
        behavior: "smooth",
      });
    } else {
      // Scroll using ID with querySelector
      const targetElement = document.querySelector(`#${linkName}`);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - SCROLL_OFFSET,
          behavior: "smooth",
        });
      }
    }
  };
  

  const handleLogoClick = () => {
    if(hasUTMParams) return;
    navigate("/");
  };

  return (
    <>
      {!isMobile ? (
        <header className="header-version">
          <img
            src={logo}
            alt="Logo"
            className="left-logo"
            onClick={handleLogoClick}
            style={{ cursor: "default" }}
          />
          <div className="nav-container-version">
            <nav className="nav-links">
            <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection(homeRef, "home");
                }}
                className={activeLink === "home" ? "active-link" : ""}
              >
                HOME
              </NavLink>
              <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection(overviewRef, "overview");
                }}
                className={activeLink === "overview" ? "active-link" : ""}
              >
                OVERVIEW
              </NavLink>
              <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection(amenitiesRef, "amenities");
                }}
                className={activeLink === "amenities" ? "active-link" : ""}
              >
                AMENITIES
              </NavLink>
              <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection(floorPlanRef, "floor-plans");
                }}
                className={activeLink === "floor-plans" ? "active-link" : ""}
              >
                FLOOR PLAN
              </NavLink>
              <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection(locationRef, "location");
                }}
                className={activeLink === "location" ? "active-link" : ""}
              >
                LOCATION
              </NavLink>
              <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection(galleryRef, "gallery");
                }}
                className={activeLink === "gallery" ? "active-link" : ""}
              >
                GALLERY
              </NavLink>
              <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToSection(enquireRef, "enquire");
                }}
                className={activeLink === "enquire" ? "active-link" : ""}
              >
                ENQUIRE NOW
              </NavLink>
            </nav>
          </div>
        </header>
      ) : (
        <div className="mobile-footer-version">
          <nav className="mobile-nav-links">
            <a
              href="#overview"
              onClick={(e) => {
                e.preventDefault();
                handleScrollToSection(overviewRef, "overview");
              }}
              className={activeLink === "overview" ? "active-link" : ""}
            >
              OVERVIEW
            </a>
            <a
              href="#amenities"
              onClick={(e) => {
                e.preventDefault();
                handleScrollToSection(amenitiesRef, "amenities");
              }}
              className={activeLink === "amenities" ? "active-link" : ""}
            >
              AMENITIES
            </a>
            <a
              href="#floor-plan"
              onClick={(e) => {
                e.preventDefault();
                handleScrollToSection(floorPlanRef, "floor-plans");
              }}
              className={activeLink === "floor-plans" ? "active-link" : ""}
            >
              FLOOR PLAN
            </a>
            <a
              href="#location"
              onClick={(e) => {
                e.preventDefault();
                handleScrollToSection(locationRef, "location");
              }}
              className={activeLink === "location" ? "active-link" : ""}
            >
              LOCATION
            </a>
            <a
              href="#gallery"
              onClick={(e) => {
                e.preventDefault();
                handleScrollToSection(galleryRef, "gallery");
              }}
              className={activeLink === "gallery" ? "active-link" : ""}
            >
              GALLERY
            </a>
            <a
              href="#enquire"
              onClick={(e) => {
                e.preventDefault();
                handleScrollToSection(enquireRef, "enquire");
              }}
              className={activeLink === "enquire" ? "active-link" : ""}
            >
              ENQUIRE NOW
            </a>
          </nav>
        </div>
      )}
    </>
  );
};

export default HeaderVersion;
