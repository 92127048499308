import React from 'react';
import '../styles/PrivacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use, and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law
        protects You. We use Your Personal data to provide and improve the
        Service. By using the Service, You agree to the collection and use of
        information in accordance with this Privacy Policy.
      </p>
      <p>
        <em>
          This Privacy Policy has been created with the help of the Privacy
          Policy Generator.
        </em>
      </p>

      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalised have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <strong>Account:</strong> A unique account created for You to access
          our Service or parts of our Service.
        </li>
        <li>
          <strong>Affiliate:</strong> An entity that controls, is controlled by
          or is under common control with a party.
        </li>
        <li>
          <strong>Company:</strong> SHVX, Shivaji Park, Mumbai 400016.
        </li>
        <li>
          <strong>Cookies:</strong> Small files placed on Your device
          containing browsing details.
        </li>
        <li>
          <strong>Country:</strong> Maharashtra, India.
        </li>
        <li>
          <strong>Device:</strong> Any device that can access the Service such
          as a computer, a cellphone, or a tablet.
        </li>
        <li>
          <strong>Personal Data:</strong> Any information relating to an
          identified or identifiable individual.
        </li>
        <li>
          <strong>Service:</strong> Refers to the Website.
        </li>
        <li>
          <strong>Website:</strong> Accessible from{" "}
          <a href="https://www.shvx.in" target="_blank" rel="noopener noreferrer">
            https://www.shvx.in
          </a>
          .
        </li>
        <li>
          <strong>You:</strong> The individual accessing or using the Service,
          or the company, or other legal entity.
        </li>
      </ul>

      <h2>Collecting & Using Your Personal Data</h2>
      <h3>Personal Data</h3>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information including, but not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Usage Data</li>
      </ul>

      <h3>Usage Data</h3>
      <p>
        Usage Data is collected automatically when using the Service. This may
        include information such as Your Device's IP address, browser type,
        version, pages visited, and other diagnostic data.
      </p>

      <h3>Tracking Technologies and Cookies</h3>
      <p>
        We use Cookies and similar tracking technologies to track activity on
        Our Service and store certain information. Cookies can be
        "Persistent" or "Session" Cookies:
      </p>
      <ul>
        <li>
          <strong>Session Cookies:</strong> Deleted after you close your
          browser.
        </li>
        <li>
          <strong>Persistent Cookies:</strong> Remain until manually deleted.
        </li>
      </ul>

      <h3>Use of Your Personal Data</h3>
      <p>The Company may use Personal Data for purposes including:</p>
      <ul>
        <li>To provide and maintain our Service.</li>
        <li>To manage Your Account.</li>
        <li>To contact You with updates and information.</li>
        <li>To manage requests or transactions.</li>
      </ul>

      <h3>Retention and Transfer of Your Personal Data</h3>
      <p>
        We retain your data as long as necessary for compliance with laws and
        our obligations. Your data may be transferred securely to other
        locations as necessary.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        Our Service does not address anyone under the age of 13. If You become
        aware of data provided by a child, please contact Us to remove it.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Changes will be
        posted on this page.
      </p>

      <p>If you have any questions, contact us at info@shvx.in.</p>
    </div>
  );
};

export default PrivacyPolicy;
