import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import "../../styles/ProjectPageCarousel.css";
import DownloadBrochureForm from "../DownloadBrochure";



// import Nakshatra_veda1_image1 from "../../images/Carousel_nakshatra-veda-i_image1.png";
// import Nakshatra_veda1_image2 from "../../images/Carousel_nakshatra-veda-i_image2.jpg";
// import Nakshatra_veda1_image3 from "../../images/Carousel_nakshatra-veda-i_image3.jpg";

import Nakshatra_veda1_image1 from "../../images/Carousel_nakshatra-veda-i_elevation_image1.jpg";
import Nakshatra_veda1_image2 from "../../images/Carousel_nakshatra-veda-i_elevation_image2.jpg";
import Nakshatra_veda1_image3 from "../../images/Carousel_nakshatra-veda-i_elevation_image3.jpg";
import Nakshatra_veda1_image4 from "../../images/Carousel_nakshatra-veda-i_amenities_image4.jpg";
import Nakshatra_veda1_image5 from "../../images/Carousel_nakshatra-veda-i_amenities_image5.jpg";
import Nakshatra_veda1_image6 from "../../images/Carousel_nakshatra-veda-i_amenities_image6.jpg";
// import Nakshatra_veda1_image7 from "../../images/Carousel_nakshatra-veda-i_interiors_image7.jpg";
// import Nakshatra_veda1_image8 from "../../images/Carousel_nakshatra-veda-i_interiors_image8.jpg";
// import Nakshatra_veda1_image9 from "../../images/Carousel_nakshatra-veda-i_interiors_image9.jpg";

import Nakshatra_Aazstha_image1 from "../../images/Carousel_Nakshatra Aazstha_image1.png";
// import Nakshatra_Aazstha_image2 from "../../images/Carousel_Nakshatra Aazstha_image2.png";
// import Nakshatra_Aazstha_image3 from "../../images/Carousel_Nakshatra Aazstha_image3.png";
import Nakshatra_Aazstha_image4 from "../../images/Carousel_Nakshatra Aazstha_image4.png";
import Nakshatra_Aazstha_image5 from "../../images/Carousel_Nakshatra Aazstha_image5.png";
import Nakshatra_Aazstha_image6 from "../../images/Carousel_Nakshatra Aazstha_image6.png";
import Nakshatra_Aazstha_image7 from "../../images/Carousel_Nakshatra Aazstha_image7.png";
import Nakshatra_Aazstha_image8 from "../../images/Carousel_Nakshatra Aazstha_image8.png";

// import Nakshatra_Gokul_image1 from "../../images/Nakshatra_Gokul_image1.png";
import Nakshatra_Gokul_image1 from "../../images/Nakshatra_Gokul_new_image1.png";
import Nakshatra_Gokul_image2 from "../../images/Nakshatra_Gokul_image2.png";
import Nakshatra_Gokul_image3 from "../../images/Nakshatra_Gokul_image3.png";
import Nakshatra_Gokul_image4 from "../../images/Nakshatra_Gokul_image4.png";
import Nakshatra_Gokul_image5 from "../../images/Nakshatra_Gokul_image5.png";

// import Nakshatra_Prithvi_image1 from "../../images/Nakshatra_Prithvi_image1.png";
// import Nakshatra_Prithvi_image2 from "../../images/Nakshatra_Prithvi_image2.png";
// import Nakshatra_Prithvi_image3 from "../../images/Nakshatra_Prithvi_image3.png";

import Nakshatra_Prithvi_image1 from "../../images/Nakshatra_Prithvi_new_image1.png";
import Nakshatra_Prithvi_image2 from "../../images/Nakshatra_Prithvi_new_image2.png";
import Nakshatra_Prithvi_image3 from "../../images/Nakshatra_Prithvi_new_image3.png";
import Nakshatra_Prithvi_image4 from "../../images/Nakshatra_Prithvi_new_image4.png";
import Nakshatra_Prithvi_image5 from "../../images/Nakshatra_Prithvi_new_image5.png";
import Nakshatra_Prithvi_image6 from "../../images/Nakshatra_Prithvi_new_image6.png";

import Nakshatra_Aarambh_image1 from "../../images/Nakshatra_Aarambh_image1.png";

import Carousel_ShreeRamNagar_image1 from "../../images/Carousel_ShreeRamNagar_image1.png";

import Carousel_Nakshatra_Auris_image1 from "../../images/Carousel_Nakshatra_Auris_image1.png";
import Carousel_Nakshatra_Auris_image2 from "../../images/Carousel_Nakshatra_Auris_image2.png";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProjectPageCarouselDesktop = () => {
  const { projectId } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [price, setPrice] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
  });

  const projectData = {
    "nakshatra-veda-i": {
      images: [
        Nakshatra_veda1_image2,
        Nakshatra_veda1_image1,
        Nakshatra_veda1_image3,
        Nakshatra_veda1_image4,
        Nakshatra_veda1_image5,
        Nakshatra_veda1_image6,
        //   Nakshatra_veda1_image7,
        //   Nakshatra_veda1_image8,
        //   Nakshatra_veda1_image9
      ],
      details: [
        { label: "Project Area", value: "6 Acres" },
        { label: "Configuration", value: "1BHK , 2BHK , <br/>3BHK" },
        { label: "Sizes", value: "430 sq.ft.<br/>795 sq.ft." },
        { label: "Project Size", value: "6 Buildings" },
        { label: "Launch Date", value: "Dec 2026" },
        {
          label: "Possession Status",
          value:
            "Rera Possession - December 2025<br/>Target Possession - December 2023",
        },
        { label: "Avg. Price", value: "42L onwards" },
      ],
    },
    "nakshatra-aazstha": {
      images: [
        Nakshatra_Aazstha_image1,
        // Nakshatra_Aazstha_image2,
        // Nakshatra_Aazstha_image3,
        Nakshatra_Aazstha_image4,
        Nakshatra_Aazstha_image5,
        Nakshatra_Aazstha_image6,
        Nakshatra_Aazstha_image7,
        Nakshatra_Aazstha_image8,
      ],
      details: [
        { label: "Project Area", value: "3 Acres" },
        { label: "Sizes", value: "390 sq.ft. - 502 sq.ft." },
        { label: "Project Size", value: "6 Towers" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Possession Starts", value: "December 2023" },
        { label: "Configurations", value: "1 BHK, 1.5 BHK, 2 BHK" },
        { label: "Avg. Price", value: "₹9.05 K/sq.ft" },
      ],
    },
    "nakshatra-gokul": {
      images: [
        Nakshatra_Gokul_image1,
        Nakshatra_Gokul_image2,
        Nakshatra_Gokul_image3,
        Nakshatra_Gokul_image4,
        Nakshatra_Gokul_image5,
      ],
      details: [
        { label: "Project Area", value: "0.75 Acres" },
        { label: "Sizes", value: "489 sq.ft - 518 sq.ft" },
        { label: "Project Size", value: "3 Buildings - 359 units" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Possession Starts", value: "December 2027" },
        { label: "Configurations", value: "1 BHK, 2 BHK, 3 BHK" },
        { label: "Avg. Price", value: "46.82 L onwards" },
      ],
    },
    "nakshatra-prithvi": {
      images: [
        Nakshatra_Prithvi_image1,
        Nakshatra_Prithvi_image2,
        Nakshatra_Prithvi_image3,
        Nakshatra_Prithvi_image4,
        Nakshatra_Prithvi_image5,
        Nakshatra_Prithvi_image6,
      ],
      details: [
        { label: "Project Area", value: "3 Acres" },
        { label: "Sizes", value: "390 sq.ft. - 502 sq.ft." },
        { label: "Project Size", value: "6 Towers" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Possession Starts", value: "December 2023" },
        { label: "Configurations", value: "1 BHK, 1.5 BHK, 2 BHK" },
        { label: "Avg. Price", value: "₹9.05 K/sq.ft" },
      ],
    },
    "nakshatra-aarambh": {
      images: [Nakshatra_Aarambh_image1],
      details: [
        { label: "Project Area", value: "0.75 Acres" },
        { label: "Sizes", value: "489 sq.ft. - 932 sq.ft." },
        { label: "Project Size", value: "3 Buildings - 359 units" },
        { label: "Possession", value: "Dec, 2026" },
        { label: "Possession Starts", value: "December 2027" },
        { label: "Configurations", value: "1 BHK, 2 BHK, 3 BHK" },
        { label: "Avg. Price", value: "₹9.57 K/sq.ft - 10.07K / sq.ft." },
      ],
    },
    "shree-ram-nagar": {
      images: [Carousel_ShreeRamNagar_image1],
      details: [
        { label: "Project Area", value: "0.99 acres" },
        { label: "Sizes", value: "292-690 sq.ft" },
        { label: "Project Size", value: "1 building: 65 units" },
        { label: "Possession Starts", value: "December 2026" },
        {
          label: "Configurations",
          value: "1, 2, 3 BHKs, Villas, Commercial Spaces",
        },
        { label: "Avg. Price", value: "Price on request" },
      ],
    },
    "nakshatra-auris": {
      images: [
        Carousel_Nakshatra_Auris_image1,
        Carousel_Nakshatra_Auris_image2,
      ],
      details: [
        { label: "Project Area", value: "0.99 acres" },
        { label: "Sizes", value: "292-690 sq.ft" },
        { label: "Project Size", value: "1 building: 65 units" },
        { label: "Possession Starts", value: "December 2026" },
        {
          label: "Configurations",
          value: "1, 2, 3 BHKs, Villas, Commercial Spaces",
        },
        { label: "Avg. Price", value: "Price on request" },
      ],
    },
  };
  const brochureUrls = {
    "nakshatra-veda-i":
      "https://drive.google.com/file/d/1SAxJRukjCJcCj5l1vuVCbTSU-9z8LLWk/view",
    "nakshatra-aazstha":
      "https://drive.google.com/file/d/1DTuf6fhQDhviFkHR8H_O4P7JPqbCco4C/view?usp=sharing",
    "nakshatra-gokul":
      "https://drive.google.com/file/d/1cj2wMpgOr5YAw-hbpjSQ-L9uPNbsvaIa/view?usp=sharing",
    "nakshatra-prithvi":
      "https://drive.google.com/file/d/1Gyhotnekk9L80ZSyvgc1F1NbN1TeBZEF/view?usp=sharing",
    "nakshatra-aarambh":
      "https://drive.google.com/file/d/1rKi5iXb7l5T41cYq1ze-pq-Sx9GERtHz/view?usp=sharing",
    "shree-ram-nagar":
      "https://drive.google.com/file/d/1hQiSQ9UA5jjaSTYrIk7uJVsx-Iv9Re7o/view?usp=sharing",
    "nakshatra-auris":
      "https://drive.google.com/file/d/1ThFkE_mG0YZKr1y9JSVs8pYAWguU9RxQ/view?usp=sharing",
  };

  const brochureUrl = brochureUrls[projectId];
  const data = projectData[projectId] || { images: [], details: [] };

  const handleStepChange = (step) => setActiveStep(step);

  const handleOpenPriceModal = () => setIsPriceModalOpen(true);

  const handleClosePriceModal = () => setIsPriceModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePriceSubmit = (e) => {
    e.preventDefault();

    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/");
    const projectName = pathSegments.includes("projects")
      ? pathSegments[pathSegments.indexOf("projects") + 1]
      : "";

    if (!projectName) {
      alert("Invalid URL: Unable to determine project name.");
      return;
    }

    const payload = {
      ...formData,
      projectName: projectName.replace(/-/g, " "),
    };

    setPrice("");
    fetch("https://api.shvx.in/api/v1/lead/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eGzFywzn9i0S", //Live
        // Authorization: "Bearer nKhakeSNkAVc", //dummy for testing
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          const projectPrice = projectData[projectId]?.details.find(
            (detail) => detail.label === "Avg. Price"
          )?.value;

          setPrice(projectPrice || "Price not available");
        } else {
          alert(response.message || "Failed to submit data. Please try again.");
        }
      })
      .catch(() => {
        alert("An error occurred. Please try again.");
      })
      .finally(() => {
        setIsPriceModalOpen(false);
      });
  };

  return (
    <div id="home" className="desktop-ProjectPageCarousel-container">
      <DownloadBrochureForm brochureUrl={brochureUrl} />
      <AutoPlaySwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
        className="desktop-ProjectPageCarousel-carousel"
      >
        {data.images.map((image, index) => (
          <div
            key={index}
            className="desktop-ProjectPageCarousel-carousel-item"
          >
            <img
              src={image}
              alt={`Slide ${index}`}
              className="desktop-ProjectPageCarousel-carousel-image"
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <div className="desktop-ProjectPageCarousel-details-section">
        {data.details.map((detail, index) =>
          detail.label === "Avg. Price" ? (
            <div
              key={index}
              className="desktop-ProjectPageCarousel-details-item"
            >
              <p className="desktop-ProjectPageCarousel-details-item-title">
                <strong>{detail.label}</strong>
              </p>
              {price ? (
                <p className="desktop-ProjectPageCarousel-details-item-description price-description">
                  {price}
                </p>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleOpenPriceModal}
                  sx={{
                    backgroundColor: "#dca52e",
                    "&:hover": { backgroundColor: "#c49529" },
                    marginTop: "10px",
                  }}
                >
                  Check Price
                </Button>
              )}
            </div>
          ) : (
            <div
              key={index}
              className="desktop-ProjectPageCarousel-details-item"
            >
              <p className="desktop-ProjectPageCarousel-details-item-title">
                <strong>{detail.label}</strong>
              </p>
              <div
                className="desktop-ProjectPageCarousel-details-item-description"
                dangerouslySetInnerHTML={{ __html: detail.value }}
              />
            </div>
          )
        )}
      </div>

      <Modal open={isPriceModalOpen} onClose={handleClosePriceModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <h3
            style={{
              color: "#dca52e",
              fontSize: "1.2rem",
              fontWeight: 600,
            }}
          >
            Check Price
          </h3>
          <form onSubmit={handlePriceSubmit}>
            <TextField
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              name="contactNumber"
              label="Phone Number"
              value={formData.contactNumber}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: "#dca52e",
                "&:hover": { backgroundColor: "#c49529" },
              }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ProjectPageCarouselDesktop;
