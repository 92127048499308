import React, { useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import FloorPlans from "../components/FloorPlans";
import ProjectPageCarousel from "../components/ProjectPageCarousel/ProjectPageCarousel";
import AmenitiesSection from "../components/AmenitiesSection";
import ProjectGallery from "../components/ProjectGallery/ProjectGallery";
import OtherProjects from "../components/OtherProjects/OtherProjects";
import ProjectDescription from "../components/ProjectDescription";
import FloorPlansAzastha from "../components/FloorPlansAzastha";
import DownloadBrochure from "../components/DownloadBrochure";
import { Helmet } from "react-helmet";
import ProjectPageVersionCarouselDesktop from "../components/ProjectPageCarousel/ProjectPageVersionCarouselDesktop";
import FloorPlansVersion from "../components/FloorPlansVersion";
import AmenitiesVersion from "../components/AmenitiesVersion";

const ProjectsPage = ({ otherProjectsRef }) => {
  const { projectId } = useParams();
  const location = useLocation();
  const brochureUrls = {
    "nakshatra-veda-i":
      "https://drive.google.com/file/d/1SAxJRukjCJcCj5l1vuVCbTSU-9z8LLWk/view",
    "nakshatra-aazstha":
      "https://drive.google.com/file/d/1DTuf6fhQDhviFkHR8H_O4P7JPqbCco4C/view?usp=sharing",
    "nakshatra-gokul":
      "https://drive.google.com/file/d/1cj2wMpgOr5YAw-hbpjSQ-L9uPNbsvaIa/view?usp=sharing",
    "nakshatra-prithvi":
      "https://drive.google.com/file/d/1Gyhotnekk9L80ZSyvgc1F1NbN1TeBZEF/view?usp=sharing",
    "nakshatra-aarambh":
      "https://drive.google.com/file/d/1rKi5iXb7l5T41cYq1ze-pq-Sx9GERtHz/view?usp=sharing",
    "shree-ram-nagar":
      "https://drive.google.com/file/d/1hQiSQ9UA5jjaSTYrIk7uJVsx-Iv9Re7o/view?usp=sharing",
    "nakshatra-auris":
      "https://drive.google.com/file/d/1ThFkE_mG0YZKr1y9JSVs8pYAWguU9RxQ/view?usp=sharing",
  };

  const brochureUrl = brochureUrls[projectId];
  const projectMeta = {
    "nakshatra-veda-i": {
      title:
        "Buy Affordable Luxury 1, 2 BHK Flats & Apartments | Nakshatra Veda Vasai",
      description:
        "Discover spacious, thoughtfully designed flats & Apartments at Nakshatra Veda. Buy affordable 1, 2 & 3 BHK flats in Vasai with superior connectivity and top-notch amenities.",
      keywords:
        "buy affordable 1 BHK Flats, affordable 1 & 2 BHK Flats in Vasai, 2 BHK Apartments in Vasai",
    },
    "nakshatra-aazstha": {
      title:
        "Find Your Perfect Luxury Apartments & Home at Nakshatra Aazstha, Vasai",
      description:
        "Experience opulent living with Luxury Homes at Nakshatra Aazstha, featuring modern amenities, spacious designs, and prime connectivity in Vasai East. Explore premium comfort today!",
      keywords:
        "Luxury Home at Nakshatra, Home in Vasai, Aazstha luxury apartments in Vasai",
    },
    "nakshatra-gokul": {
      title:
        "Premium Apartments & Flats at Nakshatra Gokul, Virar – Luxurious Living Awaits",
      description:
        "Find your Premium Apartments & Flats at Nakshatra Gokul, Virar West. A blend of luxury, comfort, and connectivity in a well-connected location.",
      keywords: "premium flats Virar, Premium Apartments In Virar",
    },
    "nakshatra-prithvi": {
      title: "Premium Flats & Homes at Prime Location, Nakshatra Prithvi Vasai",
      description:
        "Explore premium flats and homes at Nakshatra Prithvi, Vasai, a prime location offering world-class amenities and unmatched convenience.",
      keywords: "Premium Flats in Vasai, prime locations Home in Vasai",
    },
    "shree-ram-nagar": {
      title: "Buy Now Modern Homes at Shree Ram Nagar Complex in Virar West",
      description:
        "Find your modern & affordable homes in Virar West at Shree Ram Nagar Complex, designed for comfort and convenience. Book your dream home today with JSB Home Makers.",
      keywords:
        "Buy homes in Virar, Modern Homes at Shree Ram Nagar, Homes in Virar West",
    },
    "nakshatra-auris": {
      title:
        "Luxury Premium Living Flats & Homes at Nakshatra Auris, Nala Sopara",
      description:
        "Discover luxury premium living flats & homes with Nakshatra Auris in Nala Sopara. Prime location flats with modern amenities. Book 1, 2 & 3 BHK Flats with JSB Home Makers.",
      keywords:
        "Luxury homes in Nala Sopara, Luxury Premium Living Flats at Nakshatra",
    },
  };

  const meta = projectMeta[projectId] || {
    title: "JSB Home Makers",
    description:
      "Top real estate builders and developers offering luxury and affordable homes.",
    keywords: "Real Estate Builders, Real Estate Builders & Developers",
  };

  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get("utm_source");
  const utmMedium = queryParams.get("utm_medium");
  const utmId = queryParams.get("utm_id");
  const gadSource = queryParams.get("gad_source");

  useEffect(() => {
    console.log("Rendering ProjectsPage with projectId:", projectId);
    console.log("Query Params:", { utmSource, utmMedium, utmId });
    window.scrollTo(0, 0);
  }, [projectId, utmSource, utmMedium, utmId, gadSource]);

  const version = () => {
    if (!utmSource && !utmMedium && !utmId && !gadSource) {
      return (
        <div>
          <ProjectPageVersionCarouselDesktop />
          <ProjectDescription />
          <AmenitiesSection />
          <FloorPlansVersion />
          <ProjectGallery />
        </div>
      );
    }

    return (
      <div>
        {projectId === "nakshatra-veda-i" && (
          <>
            <ProjectPageCarousel />
            <ProjectDescription />
            <AmenitiesVersion/>
            <FloorPlans />
            <ProjectGallery />
            {/* <FloorPlans /> */}
            {/* <FloorPlansAzastha /> */}
            {/* <OtherProjects ref={otherProjectsRef} /> */}
          </>
        )}
        {projectId === "nakshatra-aazstha" && (
          <>
            <ProjectPageCarousel />
            <ProjectDescription />
            <AmenitiesVersion/>
            <FloorPlans />
            <ProjectGallery />
            {/* <FloorPlans /> */}
            {/* <FloorPlansAzastha /> */}
            {/* <OtherProjects ref={otherProjectsRef} /> */}
          </>
        )}
        {projectId === "nakshatra-gokul" && (
          <>
            <ProjectPageCarousel />
            <ProjectDescription />
            <AmenitiesVersion/>
            <FloorPlans />
            <ProjectGallery />
            {/* <FloorPlans /> */}
            {/* <FloorPlansAzastha /> */}
            {/* <OtherProjects ref={otherProjectsRef} /> */}
          </>
        )}
        {projectId === "nakshatra-aarambh" && (
          <>
            <ProjectPageCarousel />
            <ProjectDescription />
            <AmenitiesVersion/>
            <FloorPlans />
            <ProjectGallery />
            {/* <FloorPlans /> */}
            {/* <FloorPlansAzastha /> */}
            {/* <OtherProjects ref={otherProjectsRef} /> */}
          </>
        )}
        {projectId === "nakshatra-prithvi" && (
          <>
            <ProjectPageCarousel />
            <ProjectDescription />
            <AmenitiesVersion/>
            <FloorPlans />
            <ProjectGallery />
            {/* <FloorPlans /> */}
            {/* <FloorPlansAzastha /> */}
            {/* <OtherProjects ref={otherProjectsRef} /> */}
          </>
        )}
        {projectId === "shree-ram-nagar" && (
          <>
            <ProjectPageCarousel />
            <ProjectDescription />
            <AmenitiesVersion/>
            <FloorPlans />
            <ProjectGallery />
          </>
        )}
        {projectId === "nakshatra-auris" && (
          <>
            <ProjectPageCarousel />
            <ProjectDescription />
            <AmenitiesVersion/>
            <FloorPlans />
            <ProjectGallery />
            {/* <FloorPlans /> */}
            {/* <FloorPlansAzastha /> */}
            {/* <OtherProjects ref={otherProjectsRef} /> */}
          </>
        )}
        {projectId !== "nakshatra-veda-i" &&
          projectId !== "nakshatra-aazstha" &&
          projectId !== "nakshatra-gokul" &&
          projectId !== "nakshatra-aarambh" &&
          projectId !== "nakshatra-prithvi" &&
          projectId !== "shree-ram-nagar" &&
          projectId !== "nakshatra-auris" && <div>Project not found.</div>}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
      </Helmet>
      {version()}
    </>
  );
};

export default ProjectsPage;
